// src/components/CTA.js
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { bg } from "../assets";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";

function CTA() {
  return (
    <div
      style={{
        background: `linear-gradient(to bottom, rgba(89, 116, 69, .7) 10%, rgba(89, 116, 69, .5) 90%), url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
      }}
    >
      <Container maxWidth="lg">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection={{ xs: "column", md: "row" }}
          py={4}
          gap="4rem"
        >
          {/* Text Block */}
          <Grow in={true} timeout={1200} appear={true}>
            <Box mt={3}>
              <Typography variant="h4" gutterBottom>
              The Appeal of Free Social Pokies

              </Typography>
              <Typography variant="h5" gutterBottom>
              In the world of online gaming, social pokies have emerged as a popular form of entertainment, offering a fun and engaging way to pass the time. These games are designed to mimic the excitement of traditional slot machines, but without the need for real money wagering. In this article, we will explore the world of free social pokies, discussing their appeal, how they work, and where to find the best games.               </Typography>
              <Typography variant="body1" paragraph>No financial risk: Unlike traditional slot machines, free social pokies do not require players to wager real money. This means that players can enjoy the thrill of spinning the reels without the fear of losing their hard-earned cash.
    </Typography>
    <Typography variant="body1" paragraph>Accessible and convenient: With the rise of mobile devices and high-speed internet, free social pokies are now more accessible than ever before. Players can enjoy their favorite games anytime, anywhere, making them a perfect choice for those who want to unwind during their daily commute or while waiting in line.
    </Typography>
    
              <Button
                variant="contained"
                sx={{backgroundColor: "secondary.main"}}
                component={Link}
                to="/about"
              >
                About us
              </Button>
            </Box>
          </Grow>
        </Box>
      </Container>
    </div>
  );
}

export default CTA;
