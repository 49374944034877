// src/components/Hero.js
import React from "react";
import Header from "./Header"; // Import your Header component
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { bg } from "../assets";
import Grow from "@mui/material/Grow";

function Hero() {
  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      style={{
        background: `linear-gradient(to bottom, rgba(89, 116, 69, .7) 10%, rgba(89, 116, 69, .5) 90%), url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Header />
      <Container maxWidth="xl">
        <Box p={4} display="flex" height="100%" flexDirection="column" justifyContent="center" alignItems="center" maxWidth="60%" textAlign="center" mx="auto" sx={{ backgroundColor: "rgba(89, 116, 69, .9)", borderRadius: "2rem" }}>
        <Typography variant="h3" gutterBottom style={{ fontWeight: "900" }}>
          Welcome to luxonitefit!
        </Typography>
        <Typography variant="h5" gutterBottom style={{ fontWeight: "900" }}>
          Explore a world of endless fun with our extensive collection of free games. Play instantly on any device, no downloads or logins required.
        </Typography>
        <Typography variant="body1" gutterBottom style={{ fontWeight: "900" }}>
          This platform started as a coding project among friends and has now evolved into a hub for fun and entertainment.
        </Typography>
        <Typography variant="body1" gutterBottom style={{ fontWeight: "900" }}>
          Enjoy the thrill of gaming without any financial risk. Our games do not involve the use of real money.
        </Typography>
        <Typography variant="body1" gutterBottom style={{ fontWeight: "900" }}>
        Online games can be addictive. Play responsible. 18+
        </Typography>
      </Box>
      </Container>
    </Box>
  );
}

export default Hero;
