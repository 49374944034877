import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  pallete: {
    primary: {
      dark: '#597445',
      main: '#658147',
      main600: '#658147e0',
      main500: '#516938e0',
      main400: '#729762',
      main300: '#3d5029c5',
      main200: '#3d5029a0',
      main100: '#3d5029a0',
      main50: '#f4ccff',
    },
    secondary: {
      main: 'rgb(246, 255, 0)',
      secondary: '#e6ff79',
    },
    rgba: {
      main: 'rgba(89, 116, 69, .7)',
      main600: 'rgba(149, 64, 242, 0.8)',
      main500: 'rgba(165, 73, 247, 0.8)',
      main400: 'rgba(182, 81, 252, 0.8)',
      main300: 'rgba(200, 106, 255, 0.8)',
      main200: 'rgba(218, 130, 255, 0.8)',
      main100: 'rgba(235, 155, 255, 0.8)',
      main50: 'rgba(244, 204, 255, 0.8)',
    },    
    text: {
      primary: '#ffffff',
    },
  
    success: {
        main: '#C8E4B2',
    }
  },
  typography: {
    fontFamily: 'Madimi One, sans-serif', 
    color: "white",
    body1: {
      fontSize: "18px",
    },
    h1: {
      fontSize: '3rem',
    '@media (min-width:900px)': {
      fontSize: '6rem',
    },
  },
    h3: {
      fontSize: '1.7rem',
    '@media (min-width:600px)': {
      fontSize: '3rem',
    },
    },
    h4: {
      fontSize: '1.4rem',
    '@media (min-width:600px)': {
      fontSize: '2rem',
    },
    }
  },
  spacing: [0, 4, 8, 16, 32, 64],
});

export default theme;
