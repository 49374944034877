import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import {bg} from "../assets";

function Copyright() {
  return (
    <>
    <Typography variant="body2" gutterBottom>
      {'Copyright © '}
      zenithrasell {new Date().getFullYear()}{'.'} <br/>
      </Typography>  
        <Typography variant="body1" textTransform="uppercase">
        18+.
      Does not involve the use of Real Money. <br/>
     Online games can be addictive. <br/> Play responsible  </Typography>
      </>
  );
}


export default function StickyFooter() {
  return (
      <Box
        component="footer"
        sx={{
          px: 2,
          mt: 'auto',
          backgroundColor: "#597445"
        }} 
      >
        <Container maxWidth="sm">
        <Typography variant="body1">info@zenithrasell.com</Typography>
        <Typography variant="body1">910 McGill Rd, Kamloops, BC V2C 6N6, Canadas</Typography>
        <Typography variant="body1">+12508283655</Typography>
        <br/>
        <hr style={{border: "0.1px solid #ffffff"}}/>
        <br/>

          <Typography variant="body1">
            <Link to="/terms"  style={{color: "#ffffff", textDecoration: "none"}}>
              Terms
            </Link>{' '}
            |{' '}
            <Link to="/privacy"  style={{color: "#ffffff", textDecoration: "none"}}>
              Privacy Policy{' '}
            </Link>
            |{' '}
            <Link to="/contacts"  style={{color: "#ffffff", textDecoration: "none"}}>
              Contacts
            </Link>
          </Typography>
          <Copyright />
        </Container>
      </Box>
  );
}
