import React from 'react';
import Container from "@mui/material/Container";
import Header from './Header';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TopGames from './TopGames';

export default function About() {
  return (
    <div className="about-page">
    <Header/>
    <Container>
    <Typography variant="h2" gutterBottom>
          Welcome to zenithrasell!
        </Typography>

        <Typography variant="h5" gutterBottom>
          At zenithrasell, we pride ourselves on offering the best selection of free online games, providing endless fun and entertainment for players around the globe. Whether you enjoy playing solo or with friends, our platform ensures a seamless gaming experience with no downloads, logins, popups, or other distractions. Our games are accessible on desktop, tablet, and mobile, allowing you to play at home or on the go. Each month, millions of gamers choose zenithrasell as their go-to source for their favorite games.
        </Typography>

        <Box sx={{ my: 4 }}>
          <Paper elevation={3} sx={{ p: 4, backgroundColor: "rgba(256, 256, 256, 0.2)", color: "white" }}>
            <Typography variant="h4" gutterBottom>
              Our Story
            </Typography>
            <Typography variant="body1" gutterBottom>
              zenithrasell was born out of a passion for gaming and a desire to create a platform that delivers an exceptional gaming experience to everyone. Our small but dedicated team of gaming enthusiasts started this journey with a vision to build a community where gamers could come together and enjoy the best games available. Over time, we've grown and evolved, continually adding new games and features to keep our community engaged and entertained.
            </Typography>
          </Paper>
        </Box>

        <Box sx={{ my: 4 }}>
          <Paper elevation={3} sx={{ p: 4, backgroundColor: "rgba(256, 256, 256, 0.2)", color: "white" }}>
            <Typography variant="h4" gutterBottom>
              Our Team
            </Typography>
            <Typography variant="body1" gutterBottom>
              The zenithrasell team is composed of a diverse group of talented individuals who share a common love for gaming. Our developers work tirelessly to bring you the latest and greatest games, while our support staff ensures that your experience on our platform is smooth and enjoyable. We believe in creating a collaborative and innovative environment where ideas can flourish and where every team member contributes to our shared success.
            </Typography>
          </Paper>
        </Box>

        <Box sx={{ my: 4 }}>
          <Paper elevation={3} sx={{ p: 4, backgroundColor: "rgba(256, 256, 256, 0.2)", color: "white" }}>
            <Typography variant="h4" gutterBottom>
              Our Mission
            </Typography>
            <Typography variant="body1" gutterBottom>
              Our mission at zenithrasell is to create the ultimate online gaming destination that is free and accessible to all. We are committed to providing a wide variety of games that cater to different tastes and preferences, from action-packed adventures to challenging puzzles and everything in between.
            </Typography>
          </Paper>
        </Box>

        <Typography variant="h5" gutterBottom>
          Get in Touch
        </Typography>
        <Typography variant="body1">
          We are always eager to hear from our players and developers. If you have any questions, feedback, or if you are a game developer looking to showcase your game on our platform, please reach out to us at <a href="mailto:info@zenithrasell.com">info@zenithrasell.com</a>.
        </Typography>
    <TopGames/>
    </Container>
      </div>
  )
}
